@import 'util/mixins';
@import 'components/mixins';
@import 'components/sprite';

$pink: #F4E1E8;

$drop-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.12); //0px 7px 20px -4px rgba(0,0,0,0.3);


$border-radius: 255px 18px 225px 5px/10px 225px 8px 255px; //255px 25px 225px 15px/15px 225px 15px 255px

$grid-padding: 100px;//120px;

//font-family: 'Amatic SC', cursive;
//font-family: 'Roboto', sans-serif;


//  STYLES
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Forms
//  20. Label
//  21. Media Object
//  22. Menu
//  23. Meter
//  24. Off-canvas
//  25. Orbit
//  26. Pagination
//  27. Progress Bar
//  28. Responsive Embed
//  29. Reveal
//  30. Slider
//  31. Switch
//  32. Table
//  33. Tabs
//  34. Thumbnail
//  35. Title Bar
//  36. Tooltip
//  37. Top Bar



//  1. Global
// ----------------------
.main{
    background-image: url("../img/backgrounds/slate-plate.jpg");
    // background-repeat: no-repeat;
    // background-size: 8px 12px;
    // background-size:4.53px 9.15px;
    transition:all 0.25s ease-in-out;
    background-position: center center;
}

a.anchor{
    position: absolute;
    top: 0;
    left: 0;
}


html.has-open-menu{
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  height: 100%;
  max-height: 100%;
}

// .grid-container .small-12 {
//     @include breakpoint(small only){
//         margin-bottom: rem-calc(48px);
//     }
// }

.grid-container{
    position: relative;
}

.grid-margin-x,
.grid-padding-x
{

    //padding-bottom: rem-calc(48px);
    // padding-top: 1rem; //rem-calc(48px);
    padding-top: rem-calc(map-get($grid-margin-gutters , large));
    padding-bottom: rem-calc(map-get($grid-margin-gutters , large));

    @include breakpoint(medium){
        // padding-bottom: rem-calc(map-get($grid-column-gutter, large));
        // padding-top: rem-calc(map-get($grid-column-gutter, large));
        padding-bottom: rem-calc(80px);//rem-calc($grid-padding);
        padding-top: rem-calc($grid-padding);
    }

    // @include breakpoint(medium){
    //     margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
    // }
    //
    // @include breakpoint(small only){
    //
    //     & > .cell {
    //         margin-bottom: rem-calc(32px);
    //     }
    //
    //     // .small-12.cell:last-child{
    //     //     margin-bottom: 0;
    //     // }
    // }
}

.grid-container.same-bgcolor .grid-margin-x,
.grid-container.same-bgcolor .grid-padding-x,
.half-column .grid-container .grid-padding-x {
    padding-top: 0;
}

.half-column .grid-container .grid-padding-x {
    padding-bottom: 0;
}

.grid-margin-x h1:first-of-type{
    margin-top: 0;
}

.image-right .cell,
.image-left .cell {

}

.cell{
    position: relative;
    //overflow: hidden;
    // z-index: 99;
    //padding: rem-calc(map-get($grid-margin-gutters , small)) 0;

    // padding-bottom: rem-calc(map-get($grid-margin-gutters , small));
    //
    // @include breakpoint(medium){
    //     // padding: rem-calc(map-get($grid-margin-gutters , medium)) 0;
    //     padding-bottom: rem-calc(map-get($grid-margin-gutters , medium));
    // }

    @include breakpoint(small){
        padding-bottom: rem-calc(map-get($grid-margin-gutters , small));
    }

    @include breakpoint(medium){
        padding-bottom: rem-calc(map-get($grid-margin-gutters , medium));
    }

    @include breakpoint(large){
        padding-bottom: rem-calc(map-get($grid-margin-gutters , large));
    }


    @include breakpoint(small only){
        &:last-child{
            padding-bottom: 0;
        }

    }

}

.grid-margin-x + .grid-margin-x,
.grid-padding-x + .grid-padding-x{
    padding-top: 0;
    margin-top: rem-calc(-48px);
}

.grid-padding-x{

    &.image-and-text{
        @include breakpoint(medium down){
            padding-bottom: rem-calc(80px);
        }
    }

    &.image-and-text .cell:nth-child(even){
        @include breakpoint(small only){
            padding-top: rem-calc(48px);
        }

        @include breakpoint(medium only){
            padding-top: rem-calc(80px);
        }

    }
}


// div[class*="-page"]{ //frontpage / suppage
//     background-color: #fff;
// }

.main{
    position: relative;
    overflow: auto;
    min-height: 115px;
    // padding-top: rem-calc(map-get($grid-column-gutter, small));
    // @include breakpoint(medium){
    //     padding-top: rem-calc(map-get($grid-column-gutter, large));
    // }
}

.main.content-empty{
    min-height: inherit;
}

.main.top-content-empty{

}

.main.empty,
.top-content-empty{
    .page-intro{
        padding-top: $grid-padding;
    }
}

.page-intro .cell.no-intro{
    padding-bottom: map-get($grid-column-gutter, small);
    @include breakpoint(medium){
        padding-bottom: map-get($grid-column-gutter, medium);
    }
    @include breakpoint(large){
        padding-bottom: map-get($grid-column-gutter, large);
    }
}

.with-image{
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-size: 500px; //700px
    background-position: left top;
}

.insta-section{
    padding-top: rem-calc($grid-padding);
    // @include xy-gutters(-20, padding, left right, true);
    padding-bottom: rem-calc($grid-padding);
}

#insta-feed{
    padding-top: 2rem;
    padding-bottom: 0;
}

.insta-section #insta-feed div{
    @include xy-cell(6);
    // padding-bottom: 4rem;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @include breakpoint(medium) {
        @include xy-cell(3);
    }

    @include breakpoint(large) {
        @include xy-cell(20%);
    }

    a{
        display: block;
    }
}


.footer{

    &.primary-background{
        background-color: $primary-color;
        // background-image: url("../img/backgrounds/slate-plate.jpg");
        background-repeat: no-repeat;
        // background-size: 8px 12px;
        // background-size:4.53px 9.15px;
        transition:all 0.25s ease-in-out;
        background-position: center center;
    }

    .cell{
        padding-bottom: 0;
    }

    //Textcolors
    p{
        margin-bottom: 30px;


        @include breakpoint(large){
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    a,
    p a,
    li a{
        color: $white;
    }

    padding-top: $grid-padding/2;
    // padding-bottom: $grid-padding/2;
    padding-bottom: 20px;

    @include breakpoint(large){
        padding-top: $grid-padding;
    }

    strong{
        display: block;
        // margin-bottom: 15px;

        @include breakpoint(small only){
            margin-bottom: initial;
        }
    }


    .contact-info{}


    .opening-hours{}


    .logo {
        width: 140px;
        margin-bottom: map-get($grid-column-gutter, large);

        @include breakpoint(large){
            width: 115px;
            margin-bottom: 30px;
        }

    }


    .we-made-this {
        color: $white;
        margin-top: rem-calc(map-get($grid-column-gutter, small));

        @include breakpoint(large){
            margin-top: rem-calc(map-get($grid-column-gutter, large));
        }

        a{
            font-size: rem-calc(11px);
            font-weight: 100;
        }
    }

}




// 11. Button
// ----------------------

.button
{
    min-width: 300px;
    font-weight: 600;
    text-align: center;
    // letter-spacing: -.8px;
    font-size: $global-font-size;//rem-calc(20px);
    transition: all 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);

    &.hollow
    {
        border-color: $black;
        color: $black;

        &:hover,
        &:active,
        &:focus
        {
            border-color: $black;
            color:$black;
        }
    }

    &:hover{
        box-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.2);
    }

}


.button.arrow
{
    text-align: left;
    background-repeat: no-repeat;
    background-image: url(../img/elements/icon-chevron_black.svg);
    background-position: calc(100% - 10px) center;
    background-size: 30px 9.6px;
    padding-right: 50px;

    &:hover{
        background-position: calc(100% - 5px) center;
        padding-right: 55px;
    }
}


.primary-background
// ,.transparent-background
{

    .button.hollow
    {
        border-color: $white;
        color: $white;

        &:hover,
        &:active,
        &:focus
        {
            border-color: $white;
            color:$white;
        }
    }


    .button.arrow{
        background-image: url(../img/elements/icon-chevron_white.svg);
    }
}





//     &.phone-green{
//         background-image: url(../img/elements/icon-phone-green.svg);
//     }
//     &.phone-yellow{
//         background-image: url(../img/elements/icon-phone-yellow.svg);
//     }
//     &.phone-green,
//     &.phone-yellow {
//         background-size: 25px 25px;
//         background-position: 20px center;
//         padding-left: 60px;
//
//         &:hover{
//             background-position: 15px center;
//             padding-left: 65px;
//         }
//     }
//
//
//     &.icon-map-marker-green{
//         background-image: url(../img/elements/icon-map-marker-green.svg);
//     }
//     &.icon-map-marker-yellow{
//         background-image: url(../img/elements/icon-map-marker-yellow.svg);
//     }
//     &.icon-map-marker-green,
//     &.icon-map-marker-yellow {
//         background-size: 17.57px 25.56px;
//         background-position: 20px center;
//         padding-left: 10px;
//
//         &:hover{
//             background-position: 15px center;
//             padding-left: 15px;
//         }
//     }
//
//
//     &:hover{
//         box-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.2);
//     }
// }

.button.fill-area{
    width: 100%;
}

.top-content .button{
    min-width: 200px;
    text-align: left;
}

.button.secondary{
    border-width:$button-hollow-border-width;
    border-style:solid;
    border-color: $white;
}


a.button {
    margin-right:50px;
    &:last-child{
        margin-right: 0;
    }
}



/*----------------------------------------------

Backgrounds

------------------------------------------------*/

// div[class*="-background"]{
//     border-radius: $border-radius;
// }

// div[class*="-background"]{
//     padding: rem-calc(25px);
// }


.transparent-background
{
    background-image: url("../img/backgrounds/slate-plate.jpg");
    background-size: cover;
    background-position: center center;
    color: $white;

    p, a, li, label, h1, h2, h3, h4, h5, h6
    {
        color: $white;
    }
}

.primary-background
{
    background-color: $primary-color;

    // &.transparent{
    //     background-color: rgba($primary-color, 0.85);
    // }

    &.transparency-100{  background-color: rgba($primary-color, 1);     }

    &.transparency-90{   background-color: rgba($primary-color, 0.90);  }

    &.transparency-80{   background-color: rgba($primary-color, 0.80);  }

    &.transparency-70{   background-color: rgba($primary-color, 0.70);  }

    &.transparency-60{   background-color: rgba($primary-color, 0.60);  }

    &.transparency-50{   background-color: rgba($primary-color, 0.50);  }

    &.transparency-40{   background-color: rgba($primary-color, 0.40);  }

    &.transparency-30{   background-color: rgba($primary-color, 0.30);  }

    &.transparency-20{   background-color: rgba($primary-color, 0.20);  }

    &.transparency-10{   background-color: rgba($primary-color, 0.10);  }
}



.secondary-background
{
    background-color: $secondary-color;

    // &.transparent{
    //     background-color: rgba($secondary-color, 0.85);
    // }

    &.transparency-100{  background-color: rgba($secondary-color, 1);     }

    &.transparency-90{   background-color: rgba($secondary-color, 0.90);  }

    &.transparency-80{   background-color: rgba($secondary-color, 0.80);  }

    &.transparency-70{   background-color: rgba($secondary-color, 0.70);  }

    &.transparency-60{   background-color: rgba($secondary-color, 0.60);  }

    &.transparency-50{   background-color: rgba($secondary-color, 0.50);  }

    &.transparency-40{   background-color: rgba($secondary-color, 0.40);  }

    &.transparency-30{   background-color: rgba($secondary-color, 0.30);  }

    &.transparency-20{   background-color: rgba($secondary-color, 0.20);  }

    &.transparency-10{   background-color: rgba($secondary-color, 0.10);  }
}



.gray-background
{
    background-color: $light-gray;

    // &.transparent{
    //     background-color: rgba($light-gray, 0.85);
    // }

    &.transparency-100{  background-color: rgba($light-gray, 1);     }

    &.transparency-90{   background-color: rgba($light-gray, 0.90);  }

    &.transparency-80{   background-color: rgba($light-gray, 0.80);  }

    &.transparency-70{   background-color: rgba($light-gray, 0.70);  }

    &.transparency-60{   background-color: rgba($light-gray, 0.60);  }

    &.transparency-50{   background-color: rgba($light-gray, 0.50);  }

    &.transparency-40{   background-color: rgba($light-gray, 0.40);  }

    &.transparency-30{   background-color: rgba($light-gray, 0.30);  }

    &.transparency-20{   background-color: rgba($light-gray, 0.20);  }

    &.transparency-10{   background-color: rgba($light-gray, 0.10);  }
}



.white-background
{
    background-color: $white;

    // &.transparent{
    //     background-color: rgba($white, 0.85);
    // }

    &.transparency-100{  background-color: rgba($white, 1);     }

    &.transparency-90{   background-color: rgba($white, 0.90);  }

    &.transparency-80{   background-color: rgba($white, 0.80);  }

    &.transparency-70{   background-color: rgba($white, 0.70);  }

    &.transparency-60{   background-color: rgba($white, 0.60);  }

    &.transparency-50{   background-color: rgba($white, 0.50);  }

    &.transparency-40{   background-color: rgba($white, 0.40);  }

    &.transparency-30{   background-color: rgba($white, 0.30);  }

    &.transparency-20{   background-color: rgba($white, 0.20);  }

    &.transparency-10{   background-color: rgba($white, 0.10);  }
}


.pink-background
{
    background-color: $pink;

    // &.transparent{
    //     background-color: rgba($white, 0.85);
    // }

    &.transparency-100{  background-color: rgba($pink, 1);     }

    &.transparency-90{   background-color: rgba($pink, 0.90);  }

    &.transparency-80{   background-color: rgba($pink, 0.80);  }

    &.transparency-70{   background-color: rgba($pink, 0.70);  }

    &.transparency-60{   background-color: rgba($pink, 0.60);  }

    &.transparency-50{   background-color: rgba($pink, 0.50);  }

    &.transparency-40{   background-color: rgba($pink, 0.40);  }

    &.transparency-30{   background-color: rgba($pink, 0.30);  }

    &.transparency-20{   background-color: rgba($pink, 0.20);  }

    &.transparency-10{   background-color: rgba($pink, 0.10);  }
}



// Box Strokes
// ----------------------

.primary-stroke
{
    border-color: $primary-color;
}

.secondary-stroke
{
    border-color: rgba($secondary-color, .4);
}

.white-stroke
{
    border-color: $white;
}


.gray-stroke
{
    border-color: $light-gray;
}

// div[class*="-stroke"]{
//     border-width: 1px;
//     border-style: solid;
// }


// div[class*="-12"] .primary-background{ // ^ = starter med     * = wildcard
//     margin-bottom: rem-calc(30px);
// }


.background-image div[class*="-background"]{

    padding: rem-calc(15px);

    margin-top: rem-calc(35px);
    margin-bottom: rem-calc(35px);

    @include breakpoint(medium){
        padding: rem-calc(35px);
        margin-top: rem-calc(70px);
        margin-bottom: rem-calc(70px);
    }

    .grid-margin-x{
        padding: 0;
    }

    &.with-icon{
        @include breakpoint(medium){
            margin-top: rem-calc(70px);
        }
    }

    p:last-child{
        margin-bottom: 0;
    }

}


// -- Frame
.frame-top-left,
.frame-top-right,
.frame-bottom-left,
.frame-bottom-right{
    position:absolute;
    width: 30px;
    height: 30px;
}
.frame-top-left{
    top:0;
    left:0;
    border-top:1px solid #cacaca;
    border-left:1px solid #cacaca;
}

.frame-top-right{
    top:0;
    right:0;
    border-top:1px solid #cacaca;
    border-right:1px solid #cacaca;
}

.frame-bottom-left{
    bottom:0;
    left:0;
    border-bottom:1px solid #cacaca;
    border-left:1px solid #cacaca;
}

.frame-bottom-right{
    bottom:0;
    right:0;
    border-bottom:1px solid #cacaca;
    border-right:1px solid #cacaca;
}

@include breakpoint(medium down){
    .framed.with-image img{
        margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
    }
}
// #end--Frame



.video-gallery{
    position: relative;
}

.play-icon{
    pointer-events:auto;
    &:hover::after{

        background-size: 25px;
        width: 105px;
        height: 105px;
        border-radius: 53px;

        @include breakpoint(large){
            background-size: 35px;
            width: 155px;
            height: 155px;
            border-radius: 85px
        }
    }
}

.play-icon:after{
    content:"";
    // content: attr(data-after);
    pointer-events:auto;
    display: block;

    @include absolute-center;
    cursor: pointer;
    border-radius: 75px;
    width: 100px;
    height: 100px;
    box-shadow: $drop-shadow;

    background-color: rgba(255, 255, 255, .68);
    background-image: url(../img/icons/icon-play.svg);
    background-position: calc(50% + 5px) center;
    background-repeat: no-repeat;
    background-size: 30px;

    transition:all 100ms ease-in-out;

    @include breakpoint(large){
        width: 150px;
        height: 150px;

        background-size: 40px;
    }

    &:hover{
        background-color: rgba($white, 1);
        background-size: 43px;
    }

}

// .play-icon{
//     @include absolute-center;
//     cursor: pointer;
//     border-radius: 75px;
//     width: 100px;
//     height: 100px;
//     box-shadow: $drop-shadow;
//
//     background-color: rgba(255, 255, 255, .68);
//     background-image: url(../img/icons/icon-play.svg);
//     background-position: calc(50% + 5px) center;
//     background-repeat: no-repeat;
//     background-size: 30px;
//
//     transition:all 100ms ease-in-out;
//
//     @include breakpoint(large){
//         width: 150px;
//         height: 150px;
//
//         background-size: 40px;
//     }
//
//     &:hover{
//         background-color: rgba($white, 1);
//         background-size: 43px;
//     }
//
// }

.background-image{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // width: 100%;
    min-height: 40vh;

    &.fixed{
    //@include breakpoint(medium){
        background-attachment: fixed;
    }

}

.image-title{
    color: $white;
    font-family: $header-font-family;
    font-size: rem-calc(16px);
    font-weight: 400;

    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: $white;
    min-width: 200px;
    max-width: 200px;
    position: absolute;

    &.top-left{
        top: 20px;
        left: 20px;
    }
    &.top-right{
        top: 20px;
        right: 20px;
    }
    &.bottom-left{
        bottom: 20px;
        left: 20px;

        @include breakpoint(medium down){
            margin-bottom: 40px;
        }
    }
    &.bottom-right{
        bottom: 20px;
        right: 20px;
    }

    //stroke colors
    &.primary-color{
        border-bottom-color: $primary-color;
    }
    &.secondary-color{
        border-bottom-color: $secondary-color;
    }
    &.black{
        border-bottom-color: $black;
    }
}


/*
**** TOP CONTENT ****
*/

.top-content{
    // height:90vh;

    .grid-x{
        margin-bottom: 0;
        // height:100vh;
    }

}

.top-content img{
    width: 63px;
    height: 294px;

    @include breakpoint(medium){
        width: 113px;
        height: 344px;
    }

    position: absolute;
    top: -50px;
    right: -50px;
}

// .top-content.background-image{
//     height: 100vh;
// }


/*************
** HAX - vh on iphone5
** https://gist.github.com/pburtchaell/e702f441ba9b3f76f587
*************/

/**
 * iPhone 5
 * You can also target devices with aspect ratio.
 */
@media screen and (device-aspect-ratio: 40/71) {
  .top-content.background-image,
  .top-content .grid-x
   {
       height: 568px;
       min-height: 568px;
    }
}
/*************/

.top-content{
    position: relative;
    // overflow-x: hidden;
}

// .top-content .grid-x:first-child .cell
// {
//     margin-bottom:50px;
// }

//font-sizes in editor.scss
.top-content .button{
    padding: 0.5em 1em;
}

.front-page .is-first{

    margin-top: 200px;

    @include breakpoint(medium){
        margin-top: 200px;
    }

    @include breakpoint(large){
        margin-top: 200px;
    }
}

.sub-page .page-intro + .is-first.transparent-background .grid-x{
    padding-top: 0;
}

.top-card
{
    position: absolute;
    top: 100%;
    width: 80%;
    background: $white;
    z-index: 10;

    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 940px;

    .cell{
        margin-bottom: 0;
        padding-bottom: 0;
    }

    @include breakpoint(medium){
        width: 60%;
    }


    .paragraph-container{

        font-size: rem-calc(20px);

        left: 50%;
        transform: translateX(-50%);

        @include breakpoint(medium down){
            padding: rem-calc(map-get($grid-column-gutter, medium));
        }

        @include breakpoint(medium){
            padding-left:0;
            padding-right:0;
        }
    }

}

ul.links-list {
    list-style: none;
    margin-left: 0;
    li{
        padding-left: 30px;
        background-image: url("/assets/img/icons/icon-chevron-green.svg");
        background-repeat: no-repeat;
        background-size: 8px 12px;
        // background-size:4.53px 9.15px;
        transition:all 0.25s ease-in-out;
        background-position: left center;

        &:hover{
            background-position: 10px center;
        }
    }
}


.product-list{
    h3{
        text-transform: uppercase;
    }
    ul li a{
        color: $dark-gray;
    }
}


/* Custom styles */


// .with-under-line{
//     position: relative;
//     padding-bottom: 60px;
//     // display: block;
//
//     @include breakpoint(medium){
//         padding-bottom: 80px;
//         margin-bottom:30px;
//     }
//
//     &:before{
//         content:"";
//         width: 85px;
//         height: 50px;
//         left: 50%;
//         transform: translateX(-50%);
//         // border-radius: 25px;
//
//         display: block;
//         position: absolute;
//         bottom: 0;
//         background-color: $white;
//         background-image: url(../img/logo/logo-symbol.svg);
//         background-position: center center;
//         background-repeat: no-repeat;
//         background-size: 22px 26px;
//         z-index:10;
//
//     }
//
//     &:after{
//         content:"";
//         width: 50%;
//         left: 50%;
//         transform: translateX(-50%);
//         height: 1px;
//         display: block;
//         position: absolute;
//         bottom: 25px;
//         border-bottom: 1px solid $primary-color;
//     }
// }
//
// span.with-under-line{
//     display: block;
// }
//
// .cell.with-under-line{
//     position: relative;
//     padding-bottom: 150px;
//     margin-bottom:50px;
// }
//
// .secondary-background .with-under-line:before{
//     background-color: $secondary-color;
// }
//
// .primary-background .with-under-line:before{
//     background-color: $primary-color;
// }
//
// .pink-background .with-under-line:before{
//     background-color: $pink;
// }
//
// .gray-background .with-under-line:before{
//     background-color: $light-gray;
// }



/*!
PRELOADERS
*/

/* General */
.loader-wrapper {
    background:$primary-color;
    position:fixed;
    right:0;
    top:0;
    z-index:10000;
    -moz-transition:all 600ms ease-in-out;
    -o-transition:all 600ms ease-in-out;
    -webkit-opacity:1;
    -webkit-transition:all 600ms ease-in-out;
    bottom:0;
    filter:alpha(opacity=100);
    left:0;
    opacity:1;
    overflow:hidden
}
.loader-wrapper.loaded{
    // -moz-opacity:0;
    // -webkit-opacity:0;
    // filter:alpha(opacity=0);
    opacity:0; //0
    visibility:hidden;//visible;
    zoom:1;
    // z-index:1;
    z-index:999;
}
.loader-wrapper span {display: none;}

.loader-wrapper img {
    position: fixed;
    top:50%;
    left:50%;
    max-width: 128px;
    max-height:128px;
    margin-left: -64px;
    margin-top: -128px;
}

.is-mobile .loader-wrapper,
.menu-mobile .loader-wrapper {
    display: none;
}

/* Wave loader */
.loader-wave,
.loader-wave::before {
    width:150px;
    height:15px;
    //background-color:#ff0000;
    // background-image: url(../svg/wave-rounded.svg);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='147 5394.93 66.18 6.141'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%230000c4;stroke-linecap:square;stroke-miterlimit:10;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M1,5.141C6.348,5.141,6.348,1,11.7,1s5.348,4.141,10.7,4.141S27.742,1,33.09,1s5.348,4.141,10.7,4.141S49.135,1,54.483,1s5.348,4.141,10.7,4.141' transform='translate%28147 5394.93%29'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-size: 50%;
    transform-origin:center center;
    background-position: center;
	animation: animatedBackground 1s linear infinite;
}

@keyframes animatedBackground {
	from { background-position: 0 0; }
	to { background-position: 100% 0; }
}

.loader-wave {
    width:150px;
    height:15px;
    margin-left:-75px;
    // margin-top: -12px;
    position:absolute;
    top:50%;
    left:50%;
    // animation:preloader-outer 1.5s ease infinite;
}

/* Circle Rotating */
.loader-circle,
.loader-circle::before {
    width:50px;
    height:50px;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    border-radius:50%;background:none;
    -webkit-transform-origin:center center;
    -ms-transform-origin:center center;
    transform-origin:center center
}

.loader-circle {
    width:50px;
    height:50px;
    margin-left:-25px;
    margin-top: -25px;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-animation:preloader-outer 1.5s ease infinite;
    -moz-animation:preloader-outer 1.5s ease infinite;
    -o-animation:preloader-outer 1.5s ease infinite;
    animation:preloader-outer 1.5s ease infinite;
}

.loader-circle::before {
    content:"";
    display: block;
     -webkit-animation:preloader 3s ease infinite;
     -moz-animation:preloader 3s ease infinite;
     -o-animation:preloader 3s ease infinite;
     animation:preloader 3s ease infinite;
     border:2px solid #ccc;
     border-bottom:2px solid $secondary-color;
 }
@-webkit-keyframes preloader-outer {
    from{opacity:1;-webkit-transform:rotate(0deg)}
    to{opacity:1;-webkit-transform:rotate(360deg)}
}
@keyframes preloader-outer {
    from{opacity:1;transform:rotate(0deg)}
    to{opacity:1;transform:rotate(360deg)}
}
@-webkit-keyframes preloader {
    0%{opacity:.3}
    50%{opacity:1}
    100%{opacity:.3}
}
@keyframes preloader {
    0%{opacity:.3}
    50%{opacity:1}
    100%{opacity:.3}
}


.loader-tooth svg {
  width: 125px;
  height: 125px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  path {
      fill:none;
      fill-opacity: 0;
      stroke:#000;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
      stroke-width: 1;
  }
}

.objectfit .loader-tooth svg { //only if not ie
  path {
    animation: draw 3s infinite linear;
    stroke-dasharray: 870;
    stroke-dashoffset: 870;
  }
}

// .no-objectfit .loader-tooth svg {
//   width: 125px;
//   height: 125px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
//   path {
//     fill-opacity: 0;
//     stroke: #000;
//     stroke-width: 1;
//     stroke-dasharray: 870;
//     stroke-dashoffset: 870;
//     animation: draw 3s infinite linear;
//   }
// }

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}


// @media screen and #{breakpoint(medium)} and #{breakpoint(xlarge down)} {
//   // Medium to extra large styles
// }

/* Scroll down */

// .scroll-down {
//     position: absolute;
//     width: 30px;
//     height: 30px;
//     bottom: 20px;
//     left: calc(50% - 15px);
//     border-bottom: none !important;
//     background-image: url(../img/menu-arrow-down.svg);
//     background-repeat: no-repeat;
//     background-position: center;
// }

// .icon .hamburger{
    // @include hamburger($color: $white, $color-hover: $light-gray, $width:22px);
// }

.scroll-down{
    // position: fixed;
    // bottom: 20px;
    width: 45px;
    height: 45px;
    // left: calc(50% - 23px);
    left: 30px;
    border-bottom: none!important;

    position: absolute;
    bottom: 30px;

    .scroll-down-circle{
        transition: all 600ms;
        position: relative;
        width: 45px;
        height: 45px;
        border-radius: 23px;
        transform: rotate(45deg);
        // border: 1px solid rgba($white,.2);

         .arrow {
            transition: all 600ms;
            position: absolute;
            top: 0;

            &:before{
                transition: all 600ms;
                content: '';
                position: absolute;
                top: 12px;
                left: 12px;
                width: 20px;
                height: 20px;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
            }
        }

        &:hover{
            border-color: rgba($primary-color,.2);
            background-color: $white;
            .arrow:before{
                border-right-color: $primary-color;
                border-bottom-color: $primary-color;
            }
        }
    }
}



/*----------------------------------------------

S C R O L L D O W N  D O T S

------------------------------------------------*/

.top-content .scroll-down-dots{
    position:relative;
    margin-top:150px;
}

.top-content.background-image .scroll-down-dots{
    position:absolute;
    bottom:50px;
    z-index:888;
    left:50%;
    height:0;
    margin-top: 0;
}

.scroll-down-dots{
    a{
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      padding:20px;
      display: flex;
      flex-direction:column;
        span{
          list-style: none;
          width: 8px;
          height: 8px;
          margin-bottom: 10px;
          border-radius: 4px;
          background-color:rgb(255, 133, 0);
          animation: pulse 1s ease-in-out infinite;
        }

        span:nth-child(1){
          animation-delay: 0s;
        }

        span:nth-child(2){
          animation-delay: .1s;
        }

        span:nth-child(3){
          animation-delay: .2s;
        }
    }

    @keyframes pulse {
      0% { transform: scale(0.8); opacity: 0.5; }
      50% { transform: scale(1); opacity: 1; }
      100% { transform: scale(0); opacity: 0; }
    }
}


header
{
    position: fixed;
    z-index: 999;
	//transition: all 600ms cubic-bezier(0.600, 0.300, 0.000, 1.000); /* custom */
    transition: all 400ms ease-in-out;

    padding:40px 0;

    @include breakpoint(medium){
        // padding:25px 0;
    }

    top: 0;
    left: 0;
    right: 0;


    a.logo-link{
        position: relative;
        display: flex;
        align-items: center;
    }

    .logo {
        width: 225px;

        @include breakpoint(medium){
            width: 410px;
        }

        transition: all 100ms ease-in-out;
    }

}


header.header-shrink{
    padding: 10px 0;
    background: none;

    .logo{
        opacity: 0;
    }

}

// header+.main {
//     padding-top: $grid-padding;
//     @include breakpoint(medium){
//         padding-top: 250px;
//     }
// }

/*----------------------------------------------

5. 	MENU

------------------------------------------------*/

#menu{
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    // height: 100%;
}

#menu .button{
    margin-bottom: 0;
}

#menu #main-nav .button{
    margin-right: 0;
    margin-bottom: rem-calc(map-get($grid-column-gutter, small));

    @include breakpoint(medium){
        margin-right: auto;
        margin-bottom: 0;
    }

    // &.hollow{
    //     border-color: $primary-color;
    //     color: $primary-color;
    // }

    &.arrow{
        background-image: url(../img/elements/icon-chevron_black.svg);
    }
}

.menu-btn-text{
    text-align: left;
}

@include breakpoint(medium down){
    #menu-toggler{
        margin-right: rem-calc(map-get($grid-column-gutter, small));
    }
}

.hide-main-nav{
    #main-nav{
        display: none;
    }
    #locations-nav{
        display: block;
    }
}

.menu-inner {
    // position: fixed;
    // top: 0;
    // right: 0;
    // background: $primary-color;
    // z-index: 99;

	width: 0;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	overflow: hidden;
	background: $primary-color;
    z-index: 99;

    //background:linear-gradient(0deg, rgba(0,0,196, 1), rgba(0,0,98, 1));

	// border-left: 1px solid rgba(0,0,0,0.08);
	-webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
	transition: all 600ms cubic-bezier(0.600, 0.300, 0.000, 1.000); /* custom */
	transition-timing-function: cubic-bezier(0.600, 0.300, 0.000, 1.000); /* custom */
}


/*header.menu-is-open .menu-inner { width: 100%;  }*/ /*340px;*/
.menu-is-open .menu-inner { width: 100%;  }

.is-drilldown{
    width: 100%!important
}

nav{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    overflow-y: auto;
	transition: all 0.2s ease;

    @include breakpoint(medium){
        padding: 0 50px;
    }

    @include breakpoint(large){
        padding: 0 110px;
    }

}


nav ul > li > a
{
    font-family: $header-font-family;
    font-size: rem-calc(30px);

    @include breakpoint(medium){
        font-size: rem-calc(45px);
    }

    // @include breakpoint(large){
    //     font-size: rem-calc(60px);
    // }
}


nav ul > li > a,
nav ul > li > a:focus {
    color: $black;
}

nav ul > li > a:hover {
     color:scale-color($black, $lightness: 25%);
}

nav ul.submenu > li > a
{

    @include breakpoint(medium){
        font-size: rem-calc(45px);
    }
}

nav ul > li > a:not(.new-back) {
    font-weight: 900;
    text-transform: uppercase;
}



nav ul > li > a.new-back {
    font-weight: 100;
    padding-bottom: 50px !important;
    font-size: rem-calc(20px);
}


nav .button-container{
    padding-top:30px;
    padding-left:20px;

    @include breakpoint(medium){
        padding-bottom:45px;
    }

    .button{
        @include breakpoint(small only){
            box-shadow: none;
        }
    }
}

.menu-adress{
    position: absolute;
    bottom: 20px;
    display: none;

    @include breakpoint(medium){
        display: block;
        left:75px;
    }

    @include breakpoint(large){
        left:110px;
    }

    // color: $primary-color;

    // a{
    //     color: $primary-color;
    // }
}


.drilldown .is-drilldown-submenu{
    background: transparent;
}

.drilldown .is-drilldown-submenu.is-active{
    background:$white;
}

// .drilldown .is-submenu-item a,
.drilldown a{
    background: transparent;
}

.drilldown .is-drilldown-submenu-item{
    background:$white;
}

@include breakpoint(small only){
    .drilldown .is-drilldown-submenu li > a{
        font-size: 1.5rem;
    }
}

 // .drilldown a,
 .menu .is-active > a {
     background: transparent;
     color: $secondary-color; //:scale-color($black, $lightness: 25%);
}

.drilldown .js-drilldown-back > a::before{
    border-color: transparent $white transparent transparent;
}

.drilldown .is-drilldown-submenu-parent > a::after{
    border-color: transparent transparent transparent $white;
}

.menu li{
    background-image: none;
    margin-right: 0;
    padding-left: 0;
    margin-bottom: inherit;
}

/*----------------------------------------------

6. 	MENU TOGGLE (Hamburger)

------------------------------------------------*/



.toggle-button{
    min-width: 40px;
    min-height: 40px;
}


.menu-toggle {
    background-color: $light-gray;
}

.is-off-canvas-open .menu-toggle {
    background-color: $primary-color;

    .hamburger,
    .cross
    {
        &:after,
        &:before
        {
            background-color: $white;
        }
    }


}


.menu-toggle {
    box-shadow: 0 0 20px 0 rgba($black, 0.12);

    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    display: block;
    z-index: 999;

    // right: 20px;
    width: 40px;
	height: 40px;


	display: block;
	cursor: pointer;
	overflow: hidden;
	// margin-right: -3px;
	// transition: height 0.3s ease, transform 0.4s ease;
    transition: all 0.3s ease;

    @include breakpoint(medium){
        transform: translateY(-50%);
    }

    .menu-icon{
        display: inline-block;
        float: right;
    }
}


/* hamburger icon / animation */
.menu-toggle span.hamburger,
.menu-toggle span.hamburger:after,
.menu-toggle span.hamburger:before {
    content: "";
    display: block;
    height: 3px; //stroke width
    background: $black;
    position: absolute;
    top: 50%; margin-top: -1px;
    left: 50%;

    width: 20px;
    margin-left: -10px; //half the width in minus

    transition: all 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-timing-function: cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-delay: 0.15s;
}

.menu-toggle span.hamburger:before {
    width: 10px;
    right: 85%;
}

.menu-toggle span.hamburger:after { margin-top: -7px; top: 0; transition-delay: 0.27s; }
.menu-toggle span.hamburger:before { margin-top: 7px; top: 0; transition-delay: 0.2s; }

.is-off-canvas-open .menu-toggle span.hamburger,
.is-off-canvas-open .menu-toggle span.hamburger:after,
.is-off-canvas-open .menu-toggle span.hamburger:before {
	transition-delay: 0.12s;
  	transform: translateX(50px);
}
.is-off-canvas-open .menu-toggle span.hamburger:after { transition-delay: 0s; }
.is-off-canvas-open .menu-toggle span.hamburger:before { transition-delay: 0.07s; }



/* cross icon / animation */
.menu-toggle span.cross:before,
.menu-toggle span.cross:after {
	content: "";
	display: block;
	height: 3px; //stroke width
	background: $black;
	position: absolute;
	top: 50%;
    margin-top: -1.5px;
    left: 50%;

	width: 20px;
    margin-left: -10px; //half the width in minus

	transition: transform 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
	transition-timing-function: cubic-bezier(0.600, 0.000, 0.200, 1.000);
  	transform: translateY(-70px) translateX(-70px) rotate(45deg);
	transition-delay: 0.12s;
}

.menu-toggle span.cross:after {
	transition-delay: 0s;
  	transform: translateY(-70px) translateX(70px) rotate(-45deg);
}

.is-off-canvas-open .menu-toggle span.cross:before {
	transition-delay: 0.12s;
  	transform: rotate(45deg);
}
.is-off-canvas-open .menu-toggle span.cross:after {
	transition-delay: 0.24s;
  	transform: rotate(-45deg) !important;
}

.is-off-canvas-open #menu
{
    .menu-toggle span.hamburger:after,
    .menu-toggle span.hamburger:before {
        background: $white;
    }
}

#menu .menu-toggle div:first-child{
    float:right;
    display:inline-block
}

#menu .toggle-button{
    z-index: 999;
}



/*----------------------------------------------

20. Label

------------------------------------------------*/

.primary-background label{
    color: $white;
}



/* ICONS
-------------------------------------- */

// a[class*="icon-"],
// div[class*="icon-"]:before{
//     background-color:$white; //$primary-color;
//     background-repeat: no-repeat;
//     background-position: center;
//     &:hover{
//         background-color:$white;
//     }
// }
//
// a[class*="icon-"]:hover{
//     background-color:$white;
// }
//
//
// .icon
// {
//     background-color: $primary-color;
//     padding: 15px;
//     width: 70px;
//     border-radius: 40px;
// }


// [type='submit'], [type='button'] {
//     border-radius: $button-radius !important;
// }


/************
* SVG
************/

svg.mapmarker{
    stroke:#ffffff;
    fill:transparent;
}

svg.quotationmarks{
    stroke:$secondary-color;
    fill:transparent;
    stroke-width:4px;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
}

// //https://codepen.io/jdsteinbach/pen/MYPgPp
// // ul.shortcut-icons.expanded,
// ul.shortcut-icons.expanded li a
// {
//     animation: animIn 1s ease-in-out;
//     transition: transform 1s ease-in-out, opacity 1s ease-in-out; //.3s
//     display: block;
// }
//
// // ul.shortcut-icons.collapsed
// ul.shortcut-icons.collapsed li a
// {
//     animation: animOut 1s ease-in-out;
//     transition: transform 1s ease-in-out, opacity 1s ease-in-out;
//     display: none;
// }
//
// @keyframes animIn {
//   0% {
//     display: none;
//     opacity: 0;
//     height: 0;
//   }
//   1% {
//     display: block;
//     opacity: 0;
//     transform: scale(0);
//   }
//   100% {
//     opacity: 1;
//     height: auto;
//     transform: scale(1);
//   }
// }
//
// @keyframes animOut {
//   0% {
//     opacity: 1;
//     height: auto;
//   }
//   1% {
//     display: block;
//     opacity: 1;
//     transform: scale(1);
//   }
//   100% {
//     opacity: 0;
//     height: 0;
//     display: none;
//     transform: scale(0);
//   }
// }

.logo-container hr
{
    height: 13px;
    border: none;
    margin: 0;
    width: 50px;
    float: left;

    &:before{
        content: " ";
        display: block;
        border-top: 3px solid $white;
        width: 20px;
        margin: 15px auto;
        margin-top: 0;
    }

    @include breakpoint(small only){
        display: none;
    }
}


a.show-shortcuts
{
    display: none;
    float: left;
    clear: left;

    width: 50px;
    height: 50px;
    // margin-top: 60px;
    background-color: $primary-color;
    border-radius: 25px;
    background-size: 65%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/icons/icon-chevron-white.svg);
    &:hover{
        background-color: $white;
        background-image: url(../img/icons/icon-chevron-blue.svg);
    }

}


// stroke before shortcuts
// ul.shortcut-icons:before{
//     content: " ";
//     display: block;
//     // border-top: 3px solid $white;
//     width: 20px;
//     margin: 15px auto;
//     margin-top: 0;
// }


ul.shortcut-icons li{
    position: relative;
    line-height: 0;

    margin-bottom: rem-calc(map-get($grid-column-gutter, small))/2;

    opacity: 1;
    transition: all .75s ease;
}


ul.shortcut-icons li a{
    background-color: $primary-color;
    width: 45px;//50px;
    height: 45px;//50px;
    border-radius: 23px;//25px;
    transition: .25s ease-in-out;
    z-index: 1;
    box-shadow: $drop-shadow;

    &:hover+.arrow{
        left: 68px;

        opacity: 1;
    }

    &.active{
        background-color: $white;
    }
}



figure{
    // margin-bottom: rem-calc(map-get($grid-column-gutter, small));
    // @include breakpoint(medium){
    //     margin-bottom: 0;
    // }
}




.m0{ margin:0 }
.mt0{ margin-top:0 }
.mr0{ margin-right:0 }
.mb0{ margin-bottom:0 }
.ml0{ margin-left:0 }
.mx0{ margin-left:0; margin-right:0 }
.my0{ margin-top:0; margin-bottom:0 }

.grid-container.mb0{
    .grid-x{
        margin-bottom: 0;
    }
}


// .fit-image{
//
//     width: 100%; // the same width and height as for the <img>
//     height: 100%;
//
//     img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//     }
//
//     .compat-object-fit {
//         background-size: cover;
//         background-position: center center;
//         display: block;
//         border-bottom: 0;
//
//         img { // hide image if object fit is not supported - opacity to 0 for the link area
//             opacity: 0;
//         }
//     }
//
// }


/* //////////////////////// OBJECT FIT //////////////////////// */
// .cell.fit-image{
//
//     // width: 100%; // the same width and height as for the <img>
//     // height: 100%;
//     padding-left: 0;
//     padding-right: 0;
//
//     // img {
//     //     width: 100%;
//     //     height: 100%;
//     //     object-fit: cover;
//     // }
// }
// //
// // .fit-image.compat-object-fit {
// //     background-size: cover;
// //     background-position: center center;
// //     display: block;
// //     border-bottom: 0;
// //     min-height: 650px;
// //
// //     img { // hide image if object fit is not supported - opacity to 0 for the link area
// //         opacity: 0;
// //     }
// // }


.fit-image{

    width: 100%; // the same width and height as for the <img>
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.compat-object-fit {
        background-size: cover;
        background-position: center center;
        display: block;
        border-bottom: 0;

        img { // hide image if object fit is not supported - opacity to 0 for the link area
            opacity: 0;
        }
    }

}

// .cell.fit-image{
//     padding-left: 0;
//     padding-right: 0;
// }
//
// .fit-image img {
//     object-fit: cover;
//     font-family: 'object-fit: cover;';
//     width: 100%;
//     // min-height: 320px;
//     height: 100%;
//
//     @include breakpoint(large){
//         min-height: 650px;
//     }
// }

.event-list{

    .event-date,
    .event-title {
        font-family: $header-font-family;
    }


    .event-date{
        color: $white;
        text-align: center;
        line-height: 0;//2.5rem;
        font-size: rem-calc(27px);

        @include breakpoint(medium){
            font-size: rem-calc(50px);
        }
    }

    .event-title {
        font-size: rem-calc(22px);
        font-weight: 600;

        @include breakpoint(medium){
            font-size: rem-calc(30px);
        }
    }

    .event-date,
    .event-details {

        padding-bottom: 20px;

        &:first-child{
            padding-top: 20px;
        }
    }

    .event-month-short{
        font-family: $body-font-family;
        font-size: rem-calc(20px);
        font-weight: 300;
        display: block;
        line-height: 1.5rem;
    }

    .event-details{

    }

    .event-title{

    }
    // div[class*="-background"]{
    //     margin-bottom: 0;
    // }

    // .event{
    //     margin-bottom: rem-calc(32px);
    //     .grid-x.full{
    //         position: relative;
    //         overflow: hidden;
    //
    //     }
    // }



    // @include breakpoint(medium){
    //     .event-date{
    //         font-size: rem-calc(24px);
    //     }
    // }

}

.event-details{
    .event-date{
        position: relative;
        margin-bottom: rem-calc(map-get($grid-column-gutter, small));

        &:before{
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 20px;
            position: absolute;
            display: inline-block;
            margin-top: -5px;

            background: $primary-color url(../img/icons/icon-clock-white.svg) no-repeat center;
            padding: 1rem;
            // background-size: 70%;
            //background-image:url(../img/icons/icon-clock-white.svg);
            background-size: 65%;
        }
        strong{
            padding-left: 40px;
        }
    }
}



/* Images and galleries */
figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    background-color: $white;
    text-align: left;
}



/*----------------------------------------------

ContentElementFlexbox.cshml

------------------------------------------------*/

// image on top
.grid-x.imageTop div:first-child{
    margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
}



/*----------------------------------------------

ImageCollage.cshml

------------------------------------------------*/

.image-gallery, .single-image{
    text-align: center;

    @include breakpoint(medium){
        min-height: 650px;
    }
}

.image-gallery{

    figure {
        position: relative;
    }

    &>figure {
        display: none;
    }

    figure:first-of-type {
        display: block;
    }

    a {
        border-bottom: 0 !important;
    }
}

.image-gallery .cell{
      padding-bottom: 0;
}

// .image-gallery{
//     a.show-gallery{
//         padding: 30px;
//         border-radius: 63px;
//         position: absolute;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%, -50%);
//         z-index: 999;
//         background-color: $white;
//         display: inline;
//
//         span{
//             width: 60px;
//             height: 60px;
//             display:block;
//             text-align:center;
//             font-size: .85rem;
//         }
//     }
//
// }

.image-gallery div.flex-dir-column:nth-child(2){

    display: none;

    @include breakpoint(medium){
        display: block;
    }
}

// .image-gallery div.flex-dir-column
// {
//     @include breakpoint(medium){
//         &:first-child{
//             padding-right: 10px;
//         }
//
//         &:last-child{
//             padding-left: 10px;
//         }
//     }
//
//     @include breakpoint(small only){
//         &:first-child{
//             margin-bottom: 20px;
//         }
//     }
//
//     figure{
//         margin-bottom: 20px;
//         &:last-child{
//             margin-bottom: 0;
//         }
//     }
// }



/*----------------------------------------------

ParagraphImageText.cshml

------------------------------------------------*/

.image-title-text{
    position:absolute;
    color:#fff;
    background-color:$primary-color;
    text-align:center;
    font-size:.75rem;
    padding:15px;

    &.image-title-full{
        width:100%;
        left:0;
        padding:5px;
    }

    // X
    &.image-title-right{
        right: 0;
    }

    &.image-title-center{
        left: 50%;
        transform: translateX(-50%);
    }

    &.image-title-left{
        left: 0;
    }

    // Y
    &.image-title-top{
        top: 0;
    }

    &.image-title-bottom{
        bottom: 0;
    }

}



/*----------------------------------------------

ParagraphSlideshow.cshml

------------------------------------------------*/

.slide-show .slide-show-textbox {

    width: 100%;

    &.overlay{
        @include breakpoint(large){
            position: absolute;
            bottom: 0;
            z-index: 99;
            margin-bottom:0;

            @include breakpoint(portrait) {
                position: static;
                bottom: auto;
            }

        }
    }

}


/*----------------------------------------------

HEADROOM

------------------------------------------------*/
// .headroom {
//     will-change: transform;
//     transition: transform 200ms linear;
// }
// .headroom--pinned {
//     display: block;
//     transform: translateY(0%);
// }
// .headroom--unpinned {
//     display: none;
//     transform: translateY(-100%);
// }



.vh100{ min-height:100vh; }
.vh90{ min-height:90vh; }
.vh80{ min-height:80vh; }
.vh70{ min-height:70vh; }
.vh60{ min-height:60vh; }
.vh50{ min-height:50vh; }
.vh40{ min-height:40vh; }
.vh30{ min-height:30vh; }
.vh20{ min-height:20vh; }
.vh10{ min-height:10vh; }

.vh100,
.vh90,
.vh80,
.vh70,
.vh60,
.vh50,
.vh40,
.vh30,
.vh20,
.vh10{
    @include breakpoint(small only){
        height: auto;
    }
}

p+.button-container{
    padding-top: rem-calc(8px);
}



/*----------------------------------------------

S C R O L L   TO   T O P

------------------------------------------------*/
#back-top {
    color: $secondary-color;
    position: fixed;
    bottom:20px;
    right: 2%;
    z-index: 100;
    display: none;
    cursor: pointer;
}



/*----------------------------------------------

Q U O T E

------------------------------------------------*/
.quote{

    // @include breakpoint(medium){
    //     min-height: 650px;
    // }

    .cell:last-child {
        padding-bottom: 0;
    }

    .cell p:last-child {
        margin-bottom: 0;
    }

    // p{
    //     font-family: $header-font-family;
    //     font-weight: 600;
    //
    //     font-size: rem-calc(17px);
    //     line-height: rem-calc(25px);
    //
    //     @include breakpoint(medium){
    //         font-size: rem-calc(25px);
    //         line-height: rem-calc(33px);
    //     }
    //
    //     &.quote-source{
    //         font-family: $body-font-family;
    //         font-size: rem-calc(16px);
    //         font-style: normal;
    //         font-weight: normal;
    //     }
    //
    // }

}

p.quote{
    font-size: rem-calc(17px);
    line-height: rem-calc(25px);

    @include breakpoint(medium){
        font-size: rem-calc(25px);
        line-height: rem-calc(33px);
    }

    font-family: $header-font-family;
    font-style: italic;
    font-weight: 600;
}

/*----------------------------------------------

L I G H T G A L L E R Y / L I G H T S L I D E R

------------------------------------------------*/

.vmb-gallery {
    .lg-outer .lg-thumb-item.active,
    .lg-outer .lg-thumb-item:hover {
        border-color: $secondary-color;
    }
}

ul.light-slidergallery {
    margin-left: 0;
    margin-bottom: 0;

    list-style: none;
    background-image: none;

    li{
        background-image: none;
        padding-left: 0;
        margin-bottom: 0;
        position: relative;
    }

    &.no-slideshow{
        width: 100%;
    }

    img{
        width: 100%;
    }
}

ul.light-slidergallery.no-slideshow > li{
    display: none;
}

ul.light-slidergallery.no-slideshow > li:first-child{
    display: block;
}


.lightSlider > li {
    position: relative;
}

p.slide-caption {
    background-color: rgba($primary-color, 0.75);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    color: $white;
    width: 100%;
    margin:0;
}

// .lSSlideOuter+p,
// .lSSlideOuter+h1,
// .lSSlideOuter+h2,
// .lSSlideOuter+h3,
// .lSSlideOuter+h4,
// {
//     margin-top: rem-calc(map-get($grid-column-gutter, medium));
// }

.image-top .lSSlideOuter
{
    margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
}


.primary-background,
.secondary-background,
.transparent-background{
    a:not(.button).lSPrev,
    a:not(.button).lSNext{
        border-bottom: none;
    }
}

/* TESTS */
.column-info {
    position: absolute;
    background-color: rgba(255,255,255,0.95);
    border: 1px solid #000;
    padding: 20px;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
}

// .centered-xy DONT USE - IE FUCKS
// {
//     position: relative;
//     transform: translateX(-50%);
//     right: -50%;
//
//     @include breakpoint(large) {
//         // transform: translate(-50%, -50%);
//         top: 50%;
//     }
// }


.flex{
    @include flex;
}

.paragraph-container
{

    width: 100%;
    position: relative;
    z-index: 99;

    @include breakpoint(large){
        width: auto;
        padding: rem-calc(map-get($grid-column-gutter, large)); //45px + padding = 60px
        padding-top: rem-calc(100px);
        padding-bottom: rem-calc(100px - 14px); //14px = 1rem
    }



    p:last-child,
    div:last-child,
    ul:last-child,
    ul:last-child li:last-child
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    @include breakpoint(large){

        max-width: 600px; //450px + 120 (2 x padding i sider)

        &.small
        {
            max-width: 520px; //400px
        }

        &.medium
        {
            max-width: 720px; //600px
        }

        &.large
        {
            max-width: 870px; //750px
        }
    }


    @include breakpoint(large down){

        max-width: 100%;

        &.small,
        &.medium,
        &.large
        {
            max-width: 100%;
        }
    }
}



/************************************
**** Container / Column settings ****
************************************/

/* Container settings */

.is-last{
    .grid-container.full,
    .grid-container.fluid
    {
        margin-bottom: 0;
    }
}

.grid-container.full,
.grid-container.fluid
{
    // margin-bottom: rem-calc(map-get($grid-column-gutter, medium))*2;

    &.background-image{
        margin-bottom: 0;
    }
}


.grid-container.full .grid-margin-x{
    margin-left: 0;
    margin-right: 0;
}

.grid-padding-x > .cell.background-image,
.grid-padding-x > .cell.with-image {


    // @include breakpoint(small only){
    //     padding-right: 0;
    //     padding-left: 0;
    // }

    @include breakpoint(medium down){
        padding-right: 0;
        padding-left: 0;
    }
}


.page-intro,
// .grid-container.headline
{
    .grid-margin-x,
    .cell{
        padding-bottom: 0;
    }

    @include breakpoint(small only){
        .grid-margin-x{
            padding-top: 3rem;
        }
    }
}
// .gray-background.half-column.with-image+.gray-background.half-column.with-image{
//     margin-top: 30px;
// }

.half-column.with-image{
    // margin-bottom: 30px;

    @include breakpoint(large){
        .cell{
            padding: 0;
        }
    }
}

.half-column.with-image:last-child{
    margin-bottom: 0;
}

.half-column.with-image + div[class*="-background"] .same-bgcolor .grid-x{
    padding-top: rem-calc($grid-padding);
}

// @include breakpoint(landscape){
//     .half-column .cell{
//         min-height: 320px;
//         @include breakpoint(large){
//             min-height: 650px;
//         }
//     }
// }




/* //////////////////////// OBJECT FIT //////////////////////// */

.cell.fit-image{
    height: auto;
    padding-left: 0;
    padding-right: 0;
}

.fit-image img {
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 100%;
    // min-height: 320px;
    height: 100%;

    @include breakpoint(large){
        min-height: 650px;
    }
}


/**********************
****  Inline List  ****
**********************/


ul.inline-list{
    margin-left: 0;
    list-style: none;

    li{
        display: inline-block;
    }

}



.primary-background{
    &>.primary-color{
        color:$white;
    }
}

/**************
**** Arrow ****
**************/

$arrow-border: 45px;

.arrow-left,
.arrow-right,
.arrow-up,
.arrow-down {
    position: absolute;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: $arrow-border solid transparent;
  border-bottom: $arrow-border solid transparent;

  border-right:$arrow-border solid $light-gray;
  top: 60px;
  right: 0;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: $arrow-border solid transparent;
  border-bottom: $arrow-border solid transparent;

  border-left:$arrow-border solid $light-gray;
  top: 60px;
  left: 0;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: $arrow-border solid transparent;
  border-right: $arrow-border solid transparent;

  border-bottom: $arrow-border solid $light-gray;
  bottom: 0;
  left: 60px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: $arrow-border solid transparent;
  border-right: $arrow-border solid transparent;

  border-top: $arrow-border solid $light-gray;
  top: 0;
  left: 60px;
}



/* Other Colors based on bg-color */
.primary-background {
     .arrow-left {
         border-right:$arrow-border solid $primary-color;
     }

     .arrow-right {
         border-left:$arrow-border solid $primary-color;
     }

}

.secondary-background{
    .arrow-left {
        border-right:$arrow-border solid $secondary-color;
    }

    .arrow-right {
        border-left:$arrow-border solid $secondary-color;
    }
}

.transparent-background{
    .arrow-left {
        border-right:$arrow-border solid #fff;
    }

    .arrow-right {
        border-left:$arrow-border solid #fff;
    }
}



@include breakpoint(medium down){
    .arrow-left,
    .arrow-right{
        top: auto;
        bottom: 0;
        left: 60px;

        width: 0;
        height: 0;
        border-left: $arrow-border solid transparent !important;
        border-right: $arrow-border solid transparent !important;

        border-top: $arrow-border solid transparent !important;
        border-bottom: $arrow-border solid $light-gray;
    }

    .small-order-1{
        // Image is first / to the left - make arrow point up
        .arrow-left,
        .arrow-right{
            width: 0;
            height: 0;
            border-left: $arrow-border solid transparent !important;
            border-right: $arrow-border solid transparent !important;

            border-bottom: $arrow-border solid $light-gray;
        }
    }
}



.logo-collection .cell{
    @include xy-cell(6);

    @include breakpoint(medium){
        @include xy-cell(3);
    }

    @include breakpoint(large){
        @include xy-cell(auto);
    }
}



// h1,
// h2,
// h3,
// h4,
// h5,
// h6
// {
//     &.with-under-line:only-child{
//         margin-bottom: 0;
//     }
// }



/*----------------------------------------------

Notification

------------------------------------------------*/

#notification
{
    display: none;
    position: fixed;
    z-index: 999;

    font-weight: bold;
    text-transform: uppercase;
    color: $white;
    font-family: $header-font-family;
    font-style: $header-font-style;



    @include breakpoint(small only){
        display: none;
        bottom: 0;
        // left: 0;
        right: 0;
    }



    @include breakpoint(medium){
        right: -500px;
        top: 300px;
        box-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.2);

        // border: 1px solid #3adb76;
        border-radius: 100px;

        &:hover{
            background-color: scale-color($black, 80)
        }
    }

}


#notification-link{

    display: block;
    color: $white;
    font-size: rem-calc(20px);
}


#notification .close{
    display: flex;
    position: absolute;
    cursor: pointer;
    font-family: "Arial", sans-serif;
    padding: 12px 6px 9px; //9px 5px 11px;
    border-radius: 15px;

    @include breakpoint(small only){
        top: 10px;
        right: 10px;
        font-size: 2rem;
        font-weight: normal;
        padding: 20px 11px 20px;
        border-radius: 30px;
    }


    @include breakpoint(medium){
        // display: none;
        // top: -6px;
        right: auto;
        // left: -6px;
        border:1px solid $white;

        &:hover{
            background-color: scale-color($black, 80)
        }
    }


    // width: 18px;
    // height: 18px;



    align-items: center;
    justify-content: center;

    background-color: $black;
    box-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.12); //0px 7px 20px -4px rgba(0,0,0,0.3);

    span{
        // position: absolute;
        // display: block;
        line-height: 0;
        // letter-spacing: 2px;

    }
}


#notification:hover .close{
    display: flex;
}



// .have-notification{
//     &:after{
//         position: absolute;
//         top: 20px;
//         left: 15px;
//         width: 18px;
//         height: 18px;
//         border-radius: 9px;
//         background-color: $alert-color;
//
//         display: flex;
//         justify-content: center;
//         align-items: center;
//
//         content: "1";
//         font-size: .8em;
//         line-height: 0;
//
//         transition: transform 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
//
//         z-index: 999;
//         transform: scale(0);
//
//         @include breakpoint(medium){
//             top: -6px;
//             left: -6px;
//         }
//     }
//
// }


// @include breakpoint(medium){
//     .have-notification:hover{
//         &:after{
//             display: none;
//         }
//
//     }
// }


html.loaded .have-notification:after{
    transform: scale(1);
	transition-delay: 2s;
}

html.loaded .is-off-canvas-open #notification{
    background-color: scale-color($primary-color, 80);
}

/*----------------------------------------------

 E-mail notification

------------------------------------------------*/

#email-notification
{
    box-shadow: 0px 7px 20px -4px rgba(0,0,0,0.5);

    .button{
        margin-top: rem-calc(map-get($grid-column-gutter, large));
        margin-bottom: 0;
    }
}





.prices{
    .flex-container{
        padding: rem-calc(map-get($grid-column-gutter, small));
        background-color: $white;
        margin-bottom: rem-calc(map-get($grid-column-gutter, small));
        border: 1px solid #dedede;
        border-radius: $global-radius;

        &:last-child{
            margin-bottom: 0;
        }
    }

    @include breakpoint(large){
        .grid-margin-x{
            padding-bottom: 0;
        }
    }

    .price-text{
        font-weight: bold;

        @include breakpoint(medium){
            padding-right: 50px;
        }

        @include breakpoint(large){
            padding-right: 100px;
        }

        @include breakpoint(small only){
            margin-bottom: rem-calc(map-get($grid-column-gutter, small));
        }

        p{
            margin-bottom: 0;
        }
    }

    .price{
        font-family: $header-font-family;
        font-style: $header-font-style;
        font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
        font-weight: $header-font-weight;

    }
}


.smiley{
    display: inline-block;
    margin-top: rem-calc(map-get($grid-column-gutter, medium));

    img{
        width: 60px;
        height: 60px;
    }
}




// REMOVE BEFORE LAUNCH
// @import 'components/inline-stylesheet';
