$offcanvas-full-screen-bg: $light-gray; //$primary-color;
$offcanvas-full-screen-side: right;
$offcanvas-full-screen-closebutton-size: 5rem;
// $offcanvas-full-screen-sizes: ()


.offcanvas-full-screen {
    @include off-canvas-base($background: $offcanvas-full-screen-bg, $transition: 0.3s ease-in-out);
    //@include off-canvas-position($position: $offcanvas-full-screen-side, $size: 100%);
    //@include off-canvas-position($position: $offcanvas-full-screen-side);
    @include off-canvas-position($position: $offcanvas-full-screen-side, $sizes: 100%);

    transform: translateX(100%);
    width: 100%;

    @include breakpoint(medium){
        width: 50%;
    }
}

.offcanvas-full-screen-inner {
  // padding: 1rem;
  // text-align: center;
}

.offcanvas-full-screen-menu {
  @include menu-base;
  @include menu-direction(vertical);

  .menu .is-active > a{
    color: $black;

    &:hover {
        color: lighten($black, 30%);
    }
  }
  // a {
  //   color: $black;
  //
  //   &:hover {
  //     color: darken($black, 30%);
  //   }
  // }

}


// 
// #main-nav ul > li > a
// {
//     color: $black;
//
//     &:hover {
//         color: lighten($black, 30%);
//     }
// }
//
// .off-canvas-full-screen-menu {}

// .offcanvas-full-screen-close {
//   color: $white;
//   font-size: $offcanvas-full-screen-closebutton-size;
//
//   &:hover {
//     color: darken($white, 30%);
//   }
// }
