.svg-icon-antal-gange-dims {
	width: 23.7px;
	height: 22.19px;
}

.svg-icon-info-dims {
	width: 23.77px;
	height: 23.75px;
}

.svg-icon-instrument-dims {
	width: 24.01px;
	height: 24.67px;
}

.svg-icon-locations-dims {
	width: 19.33px;
	height: 24.57px;
}

.svg-icon-mapmarker-dims {
	width: 25.81px;
	height: 33.01px;
}

.svg-icon-play-dims {
	width: 42px;
	height: 57.5px;
}

.svg-icon-saesonstart-dims {
	width: 22.9px;
	height: 24.91px;
}

.svg-icon-statement-dims {
	width: 65.47px;
	height: 53.24px;
}

.svg-icon-teachers-dims {
	width: 19.22px;
	height: 26.9px;
}

.svg-pattern-half-circle-dims {
	width: 37.5px;
	height: 19.7px;
}

.svg-pattern-stroke-dims {
	width: 28px;
	height: 28px;
}

.svg-pattern-wave-rounded-dims {
	width: 44.79px;
	height: 6.14px;
}

.svg-quotationmarks-dims {
	width: 65.47px;
	height: 53.24px;
}

.svg-wave-rounded-dims {
	width: 66.18px;
	height: 6.14px;
}

