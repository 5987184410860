@import 'util/mixins';
@import 'components/mixins';

@charset "UTF-8";



//  1. Global
// ----------------------


p, li, ol
{
    a[href^="tel:"]{
        text-decoration: none;
        display: inline-block;
    }
}

// Remove outline after click
a:active, a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none
}


//DELETE THIS??
// * > :last-child { margin-bottom: 0 !important; }


p a:hover {
    text-decoration: underline;
}

li a{
    color: $black
}

strong,
.strong{
    font-weight: 700;
}

.uppercase{
    text-transform: uppercase;
}


.chtype{
    font-family: "source-han-sans-simplified-c";
    font-size: rem-calc(35px);
}

// @include breakpoint(large){
//     p:last-child,
//     p:last-child a:last-child,
//     ul:last-child,
//     ol:last-child
//     {
//         margin-bottom: 0;
//     }
// }






//  5. Typography Helpers
// ----------------------

.dw_typography a.full-border{
    border-color: $black !important;
}

a.full-border{
    text-decoration: none;
    display: inline-block;

    border-width: 1px !important;
    border-style: solid !important;
    border-color: $white !important;

    padding: 5px 10px;

    &:hover{
        border-color: $primary-color !important;
    }
}


.uppercase{
    text-transform: uppercase;
}

/* - Text Colors - */

.primary-color{
    color: get-color(primary);
}



.secondary-color{
    color: get-color(secondary);
    // a{
    //     color: get-color(secondary);
    //     border-bottom: 1px solid get-color(secondary);
    //     &:hover{
    //         border-bottom: none;
    //     }
    // }
}


.pink-color{
    color: #F4E1E8;
}



.white-color{
    color: $white;

    // a{
    //     color: $white;
    //     border-bottom: 1px solid $white;
    //     &:hover{
    //         border-bottom: none;
    //     }
    // }

}



.black-color{
    color:$black;
}



.gray-color{
    color:$light-gray;
}



.force-white-text, .white:not(.button){

    p, ul li, ol li{
        color: $white !important;
    }
    color: $white !important;
    //
    // p, h1, h2, h3, h4, h5, h6, strong{
    //     color: $white !important;
    // }
}



.force-black-text, .black:not(.button){

    p, ul li, ol li{
        color: $black !important;
    }
    color: $black !important;
    //
    // p, h1, h2, h3, h4, h5, h6, strong{
    //     color: $black !important;
    // }
}



h1,
h2,
h3,
h4,
h5,
h6{

    margin-bottom: $header-margin-bottom;
    text-transform: uppercase;

    span.pre-header {
        display: block;
        font-family: $header-font-family;
        font-size: rem-calc(20px);
        text-transform: uppercase;
        font-weight: 100;
        line-height: rem-calc(24px);
    }

}

a img {
    border: none;
}


a{
    i.fa{
        font-size: .95rem;
        padding-left: .5rem;
        transition: all .25s ease-in-out;
    }

    &:hover{
        i.fa{
            padding-left:1rem;
        }
    }
}


// - ul's

ul{
    // list-style: none;
    //padding-left: 1em;
    // margin-left: 0;
}

ul li{

    // background-image: url(../img/elements/bullet-green.png); // Fallback PNG
    // background-image: url(../img/elements/bullet-green.svg);
    // background-size: 8px;
    // background-position: left 10px;
    // background-repeat: no-repeat;
    // margin-right:0.5em;
}

ul li,
ol li{
    // margin-bottom: 8px;
    // padding-left: 25px;
}

.dw_typography{
    ul li{
        // background-image: url(/Files/Templates/Designs/tanklinikken-esbjerg18/assets/img/elements/bullet-green.png); // Fallback PNG
        // background-image: url(/Files/Templates/Designs/tanklinikken-esbjerg18/assets/img/elements/bullet-green.svg);
    }
}
// ul{
//     // &:not(.leaders){
//         li{
//             margin-bottom:0.75em;
//         }
//     // }
// }


// Texts on backgrounds
// -------------------

.primary-background {

    p,
    ul > li,
    ol > li,
    h1, .h1 ,
    h2, .h2 ,
    h3, .h3 ,
    h4, .h4 ,
    h5, .h5 ,
    h6, .h6
    {
        color: $white;
    }

    // a,
    // p a,
    // li a
    // {
    //     &:not(.button){
    //         color: $white;
    //         border-bottom-width: 1px;
    //         border-bottom-style: solid;
    //         border-bottom-color: $white;
    //
    //         &:hover{
    //             border-bottom-color: $black;
    //         }
    //
    //     }
    // }
}

.secondary-background {

    p,
    ul > li,
    ol > li{
        color: $black;
    }

    // a,
    // p a,
    // li a
    // {
    //     &:not(.button){
    //         color: $black;
    //         border-bottom-width: 1px;
    //         border-bottom-style: solid;
    //         border-bottom-color: $black;
    //
    //         &:hover{
    //             border-bottom-color: $black;
    //         }
    //
    //     }
    // }
}

// .primary-background,
// .secondary-background {
//
//     h1, .h1,
//     h2, .h2,
//     h3, .h3,
//     h4, .h4,
//     h5, .h5,
//     h6, .h6
//     {
//         color: $white;
//     }
//
// }




td a,
.primary-background td a,
.secondary-background td a{
    border-bottom: none;
}

/* - /background */



/* - Manchet - */

.dw_typography{

    .manchet, .quote, .lead{
        font-size: rem-calc(25px);
        line-height: rem-calc(33px);

        font-family: $header-font-family;
        font-weight: 600;
    }

    p.quote{
        font-size: rem-calc(25px);
        line-height: rem-calc(33px);

        font-style: italic;
        font-weight: 600;
    }
}



.small-headline,
.dw_typography .small-headline
{

    font-family: $header-font-family;
    font-style: italic;
    font-weight: 600;

    font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
    line-height: rem-calc(map-deep-get($header-styles, small, h4, line-height)); //rem-calc(33px);

    @include breakpoint(medium){
        font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
        line-height: rem-calc(map-deep-get($header-styles, medium, h4, line-height));
    }
}

/* - Images - */

img,
a,
.image-gallery,
.single-image
{
    &+h1,&+h2,&+h3,&+h4,&+h5,&+h6,
    &+.h1,&+.h2,&+.h3,&+.h4,&+.h5,&+.h6,
    &+.paragraph-container{
        margin-top:2rem
    }
}


.top-content h1
{
    // font-weight: 600;
    // color: $white;
    // text-transform: uppercase;
    overflow-wrap: break-word;

    font-size: rem-calc(35px);

    @include breakpoint(medium){
        font-size: rem-calc(55px);
    }

    // @include breakpoint(medium){
    //     font-size: rem-calc(85px);
    //     line-height: rem-calc(85px);
    // }

    // @include breakpoint(large){
    //     font-size: rem-calc(100px);
    //     line-height: rem-calc(100px);
    //     padding-left: 6px; //avoid letter-cutoff
    // }

    span{
        display: block;
        font-style: normal;
        text-transform: none;
        font-weight: 500;
        font-size: .5em;
        line-height: 1.2em;
        // margin-bottom: 1em;
        // margin-top: 8px;
    }

}

// .front-page .top-content h1
// {
//     font-size: rem-calc(55px);
//
//     @include breakpoint(medium){
//         font-size: rem-calc(75px);
//         line-height: rem-calc(75px);
//     }
//
// }
